/**
 * Import React
 */
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";

/**
 * Import Components
 */
import { Popover } from "../Popover";
import { Message } from "./components/Message";
import { DotLine } from "./components/DotLine";
import { HeaderComment } from "./components/Header/HeaderComment";
import { FooterComment } from "./components/Footer/FooterComment";

/**
 * Import Utils
 */
import clsx from "clsx";
import { randomId } from "../../random";
import { useExcalidrawAppState } from "../App";
import { ShapeCache } from "../../scene/ShapeCache";
import { sceneCoordsToViewportCoords } from "../../utils";
import { mutateElement } from "../../element/mutateElement";
import { elementWithCanvasCache } from "../../renderer/renderElement";

/**
 * Import Data
 */
import { PlanningData } from "../../../excalidraw-app/data/PlanningData";
import { ReadCommentsData } from "../../../excalidraw-app/data/ReadCommentsData";

/**
 * Import Icons
 */
import { EyeOpen } from "../icons";

/**
 * Import Types
 */
import { ExcalidrawCustomCommentElement } from "../../element/types";

/**
 * Import Consts
 */
import { BACKEND_URL } from "../../../excalidraw-app/api";

/**
 * Import styles
 */
import "./CustomCommentDialog.scss";
import { getHashParam } from "../../utils/index";

/**
 * Type Props
 */
type CreateCommentProps = {
  element: ExcalidrawCustomCommentElement;
  onDeleteComment: (element_id: string) => void;
  addToHistory: () => void;
  close: () => void;
};

export const CustomCommentDialog = React.memo(
  ({ element, onDeleteComment, close, addToHistory }: CreateCommentProps) => {
    const appState = useExcalidrawAppState();
    const authUser = PlanningData.authUser;
    const users = PlanningData.users;
    const [commentValue, setCommentValue] = React.useState("");
    const { x, y } = sceneCoordsToViewportCoords(
      { sceneX: element.x, sceneY: element.y },
      appState,
    );
    const messagesContainerRef = useRef<HTMLDivElement>(null);
    const messages = element.messages;
    const [position, setPosition] = useState<"left" | "right">("right");
    const innerRef = useRef<HTMLDivElement>(null);
    const [lastTop, setLastTop] = useState(0);

    // Проверка, может ли текущий пользователь редактировать сообщение
    const messageIsEdit = useCallback(
      (user_id?: string, message_autor_id?: string) => {
        return !!(user_id && message_autor_id && user_id === message_autor_id);
      },
      [],
    );

    // Обработчик редактирования сообщения
    const onEditMessage = useCallback(
      (messageId: string, newValue: string) => {
        if (!newValue.trim()) {
          onDeleteMessage(messageId);
          addToHistory();
        } else {
          const updatedMessages = element.messages.map((message) =>
            message.id === messageId
              ? { ...message, value: newValue.trim() }
              : message,
          );
          mutateElement(element, { messages: updatedMessages });
          addToHistory();
        }
      },
      [element],
    );

    // Обработчик удаления сообщения
    const onDeleteMessage = useCallback(
      (message_id: string) => {
        const messages = element.messages.filter(
          (message) => message.id !== message_id,
        );
        if (!messages.length) {
          onDeleteComment(element.id);
          addToHistory();
        } else {
          mutateElement(element, { messages });
          addToHistory();
        }
      },
      [element],
    );

    // Создание нового сообщения
    const onCreateMessage = useCallback(
      (commentValue: string) => {
        if (commentValue.trim()) {
          mutateElement(element, {
            messages: [
              ...element.messages,
              {
                id: randomId(),
                value: commentValue.trim(),
                date: new Date().toISOString(),
                autor: authUser?.first_name ?? "",
                user_id: authUser?.id ?? "",
              },
            ],
          });
          addToHistory();
          setCommentValue("");
        }
      },
      [element, authUser],
    );

    // Изменение состояния "resolved"
    const onChangeResolved = useCallback(
      (value: boolean) => {
        mutateElement(element, {
          resolved: value,
          opacity: value ? 50 : 100,
        });
        elementWithCanvasCache.delete(element);
        addToHistory();
      },
      [element],
    );

    // Выбор цвета
    const setSelectColor = useCallback(
      (value: string) => {
        if (value !== element.backgroundColor) {
          mutateElement(element, {
            backgroundColor: value,
          });
          ShapeCache.delete(element);
          addToHistory();
        }
      },
      [element],
    );

    // Отметить как непрочитанное
    const unReadComment = useCallback(() => {
      const firstMessage = element.messages[0];
      if (firstMessage) {
        ReadCommentsData.deleteReadMessage(firstMessage.id);
        close();
      }
    }, [element, close]);

    // Данные для dropdown, мемоизированы, так как не зависят от состояния компонента
    const dropdownData = useMemo(
      () => [
        {
          icon: EyeOpen,
          text: "Отметить как непрочитанный",
          onClick: unReadComment,
        },
        {
          icon: EyeOpen,
          text: "Удалить",
          onClick: () => {
            addToHistory();
            onDeleteComment(element.id);
          },
        },
      ],
      [unReadComment, onDeleteComment, element.id],
    );

    // Данные для colorPicker, мемоизированы
    const colorPickerData = useMemo(
      () => ({
        onChangeColor: setSelectColor,
        colors: ["#368F5D", "#DCB358", "#F97D56", "#9951E4"],
      }),
      [setSelectColor],
    );

    // Прокрутка к последнему сообщению при монтировании компонента
    useEffect(() => {
      if (messagesContainerRef.current) {
        messagesContainerRef.current.scrollTop =
          messagesContainerRef.current.scrollHeight;
      }
    }, []);

    // Создание записи о прочтении сообщений
    useEffect(() => {
      element.messages.forEach((message) => {
        ReadCommentsData.createReadMessage(
          message.id,
          element.id,
          authUser?.id,
          appState.scene_id,
        );
      });
    }, [element.messages, authUser?.id, appState.scene_id]);

    useLayoutEffect(() => {
      const inner = innerRef.current;
      if (inner) {
        const { height, top } = inner.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        let newLastTop = lastTop; // Временная переменная для хранения нового значения

        // Проверяем, не уходит ли элемент за верхнюю границу экрана.
        if (top < 0) {
          newLastTop += Math.abs(top);
        } else if (top > 0 && lastTop > 0) {
          // Если элемент полностью в пределах видимости и lastTop > 0,
          // уменьшаем lastTop, не допуская выхода элемента за верхнюю границу.
          newLastTop = Math.max(0, lastTop - Math.min(lastTop, top));
        }

        // Проверяем, не уходит ли элемент за нижнюю границу экрана.
        const bottomEdge = top + height; // Нижняя граница элемента
        if (bottomEdge > viewportHeight) {
          newLastTop -= bottomEdge - viewportHeight;
        } else if (bottomEdge < viewportHeight && lastTop < 0) {
          // Если элемент полностью в пределах видимости и lastTop < 0,
          // увеличиваем lastTop, не допуская выхода элемента за нижнюю границу.
          const spaceBelow = viewportHeight - bottomEdge;
          newLastTop = Math.min(
            0,
            lastTop + Math.min(Math.abs(lastTop), spaceBelow),
          );
        }

        // Обновляем lastTop только если это необходимо
        if (newLastTop !== lastTop) {
          setLastTop(newLastTop);
        }

        if (x + 60 + 340 > viewportWidth && position !== "left") {
          setPosition("left");
        } else if (x + 60 + 340 < viewportWidth && position !== "right") {
          setPosition("right");
        }
      }
    }, [x, y]); // Добавляем x и y в массив зависимостей

    useEffect(() => {
      const inner = innerRef.current;
      if (inner) {
        inner.style.top = `calc(50% + ${lastTop}px)`;
      }
    }, [lastTop]);

    return (
      <Popover
        top={y - 9}
        left={x - (position === "left" ? -3 : 3)}
        fitInViewport={true}
        checkViewPort={false}
        offsetLeft={appState.offsetLeft}
        offsetTop={appState.offsetTop}
        viewportWidth={appState.width}
        viewportHeight={appState.height}
      >
        <div
          className={clsx("customCommentDialog__wrapper", {
            left: position === "left",
          })}
        >
          <DotLine
            color={element.backgroundColor}
            reverse={position === "left"}
          />
          <div className="customCommentDialog__inner" ref={innerRef}>
            <HeaderComment
              resolved={element.resolved}
              activeColor={element.backgroundColor}
              onChangeResolved={onChangeResolved}
              colorPickerData={colorPickerData}
              dropdownData={dropdownData}
            />
            <div
              className="customCommentDialog__inner-messages"
              ref={messagesContainerRef}
            >
              {messages.map((message) => {
                return (
                  <Message
                    key={message.id}
                    user={users?.[message?.user_id]}
                    message={message}
                    onEditMessage={onEditMessage}
                    onDeleteMessage={onDeleteMessage}
                    editable={messageIsEdit(authUser?.id, message?.user_id)}
                  />
                );
              })}
            </div>
            <FooterComment
              imagePath={
                authUser?.image && getHashParam("planning_url")
                  ? `${getHashParam("planning_url")}/service/static/${
                      authUser.image
                    }`
                  : `${BACKEND_URL}/static/default/replaceUser.jpg`
              }
              value={commentValue}
              setValue={setCommentValue}
              onSubmit={() => onCreateMessage(commentValue)}
            />
          </div>
        </div>
      </Popover>
    );
  },
);
