/**
 * Interface Of Default Event
 */
export interface IDefaultEvent {
  /**
   * Ивент
   */
  event: string;

  /**
   * Правильно ли выполнен запрос?
   */
  success: boolean;

  /**
   * Fetch ID
   */
  fetch_id?: string;
}

/**
 * Interface Of Listener
 */
export interface IListener<Payload extends Object = Object> {
  /**
   * Метод обработки
   */
  fn: (event: IEvent<Payload>) => unknown;

  /**
   * Контекст для метода
   */
  context: unknown;

  /**
   * Настройки слушателя
   */
  options?: {
    /**
     * Отработает ли 1 раз слушатель
     */
    once?: boolean;
  };
}

/**
 * Interface of modify websocket
 */
export interface IModifyWebSocket extends WebSocket {
  /**
   * Id websocket
   */
  id?: string;
}

/**
 * Type Of Event
 */
export type IEvent<O extends Object = Object> = IDefaultEvent & O;

/**
 * Instance
 */
export class Router {
  /**
   * Web Socket
   * @private
   */
  private static _ws: IModifyWebSocket | null = null;

  /**
   * Слушатели
   * @private
   */
  private static _listeners: {
    [event: string]: IListener<any>[];
  } = {};

  /**
   * Добавление слушателя
   * @param event - Ивент
   * @param fn - Метод обработки
   * @param context - Контекст
   * @param options - Настройки
   */
  static addListener<Payload extends Object = Object>(
    event: string,
    fn: IListener<Payload>["fn"],
    context: unknown = null,
    options: IListener["options"] = {},
  ) {
    /**
     * Проверка есть ли уже такой слушатель
     */
    if (this.hasListener(event, fn, context)) {
      return;
    }

    /**
     * Создание списка
     */
    if (!this._listeners[event]) {
      this._listeners[event] = [];
    }

    /**
     * Добавление
     */
    this._listeners[event].push({ fn, context, options });
  }

  /**
   * Удаление слушателя
   * @param event - Ивент
   * @param fn - Метод обработки
   * @param context - Контекст
   */
  static removeListener<Payload extends Object = Object>(
    event: string,
    fn: IListener<Payload>["fn"],
    context: unknown = null,
  ) {
    /**
     * Получение слушателя
     */
    const isExist = this.hasListener(event, fn, context);

    /**
     * Удаление слушателя
     */
    if (isExist) {
      this._listeners[event] = this._listeners[event].filter(
        (el) => el !== isExist,
      );
    }
  }

  /**
   * Есть ли такой слушатель
   * @param event - Ивент
   * @param fn - Метод обработки
   * @param context - Контекст
   */
  static hasListener<Listener extends IListener<any>>(
    event: string,
    fn: Listener["fn"],
    context: unknown = null,
  ): Listener | null {
    return (
      ((this._listeners[event] ?? []).find(
        (el) => el.fn === fn && el.context === context,
      ) as Listener) ?? null
    );
  }

  /**
   * Отправка ивента сокету
   * @param event - Ивент
   * @param payload - Данные к ивенту
   */
  static emit<Event extends string = string, Payload extends Object = Object>(
    event: Event,
    payload: Payload = {} as Payload,
  ) {
    /**
     * Проверка Active
     */
    if (!this.active) {
      throw new Error(
        "Socket: Попытка отправки данных на сокет без его инициализации",
      );
    }

    /**
     * Формирование данных для отправки
     */
    const data = Object.assign({}, payload, { event });

    /**
     * Отправка
     */
    this.ws.send(JSON.stringify(data));
  }

  /**
   * Инициализация
   * @param ws - Сокет
   */
  static init<WS extends IModifyWebSocket>(ws: WS): WS {
    /**
     * Проверка есть ли уже Socket
     */
    if (this.ws) {
      this.disconnect();
    }

    /**
     * Установка
     */
    this._ws = ws;

    /**
     * Добавление слушателя
     */
    this.ws.addEventListener("message", this.onMessage);

    return ws;
  }

  /**
   * Метод на сообщение с сокета
   * @param message - Сообщение
   * @private
   */
  static onMessage(message: MessageEvent) {
    try {
      /**
       * Попытка получения данных
       */
      const json = JSON.parse(message.data) as IEvent;

      /**
       * Проверка передан ли Event
       */
      if (json?.event) {
        /**
         * Проходимся по списку слушателей
         */
        for (const listener of [...(Router._listeners[json.event] ?? [])]) {
          /**
           * Вызов слушателя
           */
          listener.fn.call(listener.context, json);

          /**
           * Проверка Once
           */
          if (listener?.options?.once) {
            Router._listeners[json.event] = Router._listeners[
              json.event
            ].filter((el) => el !== listener);
          }
        }
      }
    } catch (_e) {}
  }

  /**
   * Очистка
   */
  static clear(disconnect: unknown | boolean = false) {
    /**
     * Очистка слушателей
     */
    this._listeners = {};

    /**
     * Проверка нужно отсоединиться от сокета
     */
    if (disconnect === true) {
      this.disconnect();
    }
  }

  /**
   * Отсоединение от сокета
   */
  static disconnect() {
    /**
     * Проверка WS
     */
    if (!this._ws) {
      return;
    }

    /**
     * Удаление слушателей
     */
    this._ws.removeEventListener("message", this.onMessage);

    /**
     * Закрываем соединение
     */
    this._ws.close();

    /**
     * Nullable
     */
    this._ws = null;
  }

  /**
   * Активен ли Router
   */
  static get active(): boolean {
    return Boolean(this.ws);
  }

  /**
   * Получение WS
   */
  static get ws(): IModifyWebSocket {
    return this._ws as IModifyWebSocket;
  }
}
