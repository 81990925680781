/**
 * Dependences
 */
import { randomId } from "../../src/random";

/**
 * Interface Of User
 */
export interface IUser {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  image?: string;
  skill_id: string;
  department_id?: string;
  ignore: boolean;
  createdAt: Date;

  permissions: "performer" | "lead" | "director" | "owner";
}

/**
 * Instance
 */
class PlanningData {
  /**
   * Список всех пользователей
   * @type {Record<string, IUser>}
   */
  users: Record<string, IUser> = {};

  /**
   * Авторизованный пользователь
   * @type {IUser}
   */
  authUser: IUser | null = null;

  /**
   * Получить всех пользователей, включая авторизованного
   */
  getUsers(): Promise<boolean> {
    return new Promise((res) => {
      /**
       * Формируем уникальный Id
       */
      const fetch_id = randomId();

      /**
       * Отправляем Запрос
       */
      window.parent.postMessage(
        {
          type: "getUsers",
          fetch_id,
        },
        "*",
      );

      const handleResponse = (
        event: MessageEvent<{
          fetch_id: string;
          type: string;
          success: boolean;
          authUser: IUser;
          users: IUser[];
        }>,
      ) => {
        if (
          event.data.fetch_id === fetch_id &&
          event.data.type === "getUsers"
        ) {
          if (event.data.success) {
            this.authUser = event.data.authUser;
            this.users = event.data.users.reduce<Record<string, IUser>>(
              (call, item) => {
                return Object.assign({}, call, { [item.id]: item });
              },
              {},
            );
            res(true);
          } else {
            res(false);
          }

          window.removeEventListener("message", handleResponse);
        }
      };
      window.addEventListener("message", handleResponse);
    });
  }
}

/**
 * Instance
 * @type {PlanningData+}
 */
const instance = new PlanningData();

/**
 * Export
 */
export { instance as PlanningData };
