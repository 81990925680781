export const getHashParam = (param: string) => {
  // Получить хеш из текущего URL
  let hash = window.location.hash;

  // Если хеш начинается с '#/', удалите этот префикс (если он присутствует)
  if (hash.startsWith("#/")) {
    hash = hash.slice(2);
  }

  // Теперь вы можете разбить хеш на параметры
  const hashParams = new URLSearchParams(hash);

  return hashParams.get(param);
};
