import { PRECEDING_ELEMENT_KEY } from "../../src/constants";
import { urlToDataUrl } from "../../src/data/image";
import { getSceneVersion } from "../../src/element";
import { ExcalidrawElement } from "../../src/element/types";
import {
  BinaryFileData,
  Collaborator,
  ExcalidrawImperativeAPI,
  Gesture,
} from "../../src/types";
import { BACKEND_URL, WS_URL } from "../api";
import { IFile } from "../api/types";
import { WS_FILES_EVENT_TYPES, WS_SCENE_EVENT_TYPES } from "../app_constants";
import { isSyncableElement, SocketUpdateDataSource } from "../data";
import { updateStaleImageStatuses } from "../data/FileManager";
import { Router } from "./Router";
import {
  BroadcastedExcalidrawElement,
  ReconciledElements,
  reconcileElements as _reconcileElements,
} from "./reconciliation";
import { randomId } from "../../src/random";
import { getHashParam } from "../../src/utils/index";

export class PlanningCollab {
  static scene_id: string | null = null;
  static lastBroadcastedOrReceivedSceneVersion: number = -1;
  static broadcastedElementVersions: Map<string, number> = new Map();
  static excalidrawAPI: ExcalidrawImperativeAPI;
  static collaborators = new Map<string, Collaborator>();
  static username: string;

  /**
   * Начало коллаборации
   */
  static startCollaboration = async (
    scene_id: string,
    excalidrawAPI: ExcalidrawImperativeAPI,
  ) => {
    return new Promise((resolve) => {
      if (
        (PlanningCollab.scene_id && PlanningCollab.scene_id !== scene_id) ||
        (!PlanningCollab.scene_id && Router.active)
      ) {
        PlanningCollab.stopCollaboration();
      }

      PlanningCollab.excalidrawAPI = excalidrawAPI;

      if (Router.active) {
        resolve(true);
      }

      const socket = Router.init(
        new WebSocket(`${WS_URL}?scene_id=${scene_id}&id=${randomId()}`),
      );

      socket.addEventListener(
        "open",
        () => {
          Router.addListener(
            "setId",
            (event: { id: string | null }) => {
              if (event.id) {
                Router.ws.id = event.id;
              }
            },
            null,
            { once: true },
          );
          resolve(true);
        },
        { once: true },
      );

      this.scene_id = scene_id;
      this.username = `${getHashParam("firstname") ?? ""} ${
        getHashParam("lastname") ?? "Unknown"
      }`;

      Router.addListener("scene-user-change", PlanningCollab.onUserChange);

      Router.addListener(
        "MOUSE_LOCATION",
        (event: {
          data: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"];
          success: boolean;
        }) => {
          if (event.success) {
            const { pointer, button, username, selectedElementIds } =
              event.data;
            const socketId: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["socketId"] =
              event.data.socketId;
            const collaborators = new Map(PlanningCollab.collaborators);
            const user = collaborators.get(socketId) || {}!;
            user.pointer = pointer;
            user.button = button;
            user.selectedElementIds = selectedElementIds;
            user.username = username;
            collaborators.set(socketId, user);
            PlanningCollab.excalidrawAPI.updateScene({
              collaborators,
            });
          }
        },
      );

      Router.addListener(
        WS_SCENE_EVENT_TYPES.UPDATE,
        PlanningCollab.onUpdateElements,
      );

      Router.addListener(
        WS_SCENE_EVENT_TYPES.UPDATE_ELEMENTS,
        PlanningCollab.onUpdateAllElements,
      );

      Router.addListener(
        WS_FILES_EVENT_TYPES.CREATE,
        PlanningCollab.onCreateFile,
      );
    });
  };

  /**
   * Конец коллаборации
   */
  static stopCollaboration = () => {
    Router.disconnect();
    PlanningCollab.scene_id = null;
    PlanningCollab.lastBroadcastedOrReceivedSceneVersion = -1;
    PlanningCollab.broadcastedElementVersions = new Map();
  };

  /**
   * Установить пользователей подключенных к сцене
   */
  static setCollaborators(sockets: string[]) {
    const collaborators = new Map();

    for (const socketId of sockets) {
      if (PlanningCollab.collaborators.has(socketId)) {
        collaborators.set(
          socketId,
          PlanningCollab.collaborators.get(socketId)!,
        );
      } else {
        collaborators.set(socketId, {});
      }
    }
    PlanningCollab.collaborators = collaborators;
    PlanningCollab.excalidrawAPI.updateScene({ collaborators });
  }

  /**
   * Метод на изменение пользователей в сцене
   */
  static onUserChange(event: { sockets: string[]; success: boolean }) {
    if (event.success) {
      PlanningCollab.setCollaborators(event.sockets);
    }
  }

  static onPointerUpdate = (payload: {
    pointer: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["pointer"];
    button: SocketUpdateDataSource["MOUSE_LOCATION"]["payload"]["button"];
    pointersMap: Gesture["pointers"];
  }) => {
    if (payload.pointersMap.size < 2 && Router.ws && Router.ws.id) {
      const data: SocketUpdateDataSource["MOUSE_LOCATION"] = {
        type: "MOUSE_LOCATION",
        payload: {
          socketId: Router.ws.id,
          pointer: payload.pointer,
          button: payload.button || "up",
          selectedElementIds:
            PlanningCollab.excalidrawAPI.getAppState().selectedElementIds,
          username: this.username,
        },
      };

      Router.emit(data.type, {
        data: data.payload,
      });
    }
  };

  /**
   * Метод на обновление элементов с сокета
   */
  static onUpdateElements(event: { elements: ExcalidrawElement[] }) {
    PlanningCollab.handleRemoteSceneUpdate(
      PlanningCollab.reconcileElements(event.elements),
    );
  }

  /**
   * Метод на обновление элементов с сокета
   */
  static onUpdateAllElements(event: { elements: ExcalidrawElement[] }) {
    PlanningCollab.handleRemoteSceneUpdate(
      PlanningCollab.updateAllElements(event.elements),
    );
  }

  /**
   * Метод на создание файла с сокета
   */
  static async onCreateFile(event: { file: IFile }) {
    const file = event.file;
    const imageCache = PlanningCollab.excalidrawAPI.getImageCache();
    if (!imageCache.get(file.file_id)) {
      try {
        const dataURL = await urlToDataUrl(`${BACKEND_URL}/static${file.link}`);
        if (dataURL) {
          const binaryData: BinaryFileData = {
            id: file.file_id,
            dataURL,
            mimeType: file.mimeType,
            created: Date.now(),
          };

          PlanningCollab.excalidrawAPI.addFiles([binaryData]);
          updateStaleImageStatuses({
            excalidrawAPI: PlanningCollab.excalidrawAPI,
            erroredFiles: new Map(),
            elements:
              PlanningCollab.excalidrawAPI.getSceneElementsIncludingDeleted(),
          });
        }
      } catch (dataURLError) {
        // Handle the error or decide what to do in case of failure
        console.error("Error in urlToDataUrl:", dataURLError);
      }
    }
  }

  /**
   * Синхронизация элементов на изменение
   */
  static syncElements = (elements: readonly ExcalidrawElement[]) => {
    PlanningCollab.broadcastElements(elements);
  };

  static broadcastElements = (elements: readonly ExcalidrawElement[]) => {
    if (
      getSceneVersion(elements) >
      PlanningCollab.getLastBroadcastedOrReceivedSceneVersion()
    ) {
      const syncableElements = elements.reduce(
        (acc, element: BroadcastedExcalidrawElement, idx, elements) => {
          if (
            (!PlanningCollab.broadcastedElementVersions.has(element.id) ||
              element.version >
                PlanningCollab.broadcastedElementVersions.get(element.id)!) &&
            isSyncableElement(element)
          ) {
            acc.push({
              ...element,
              // z-index info for the reconciler
              [PRECEDING_ELEMENT_KEY]: idx === 0 ? "^" : elements[idx - 1]?.id,
            });
          }
          return acc;
        },
        [] as BroadcastedExcalidrawElement[],
      );

      const data = {
        type: WS_SCENE_EVENT_TYPES.UPDATE,
        payload: {
          elements: syncableElements,
          scene_id: PlanningCollab.scene_id,
        },
      };

      for (const syncableElement of syncableElements) {
        this.broadcastedElementVersions.set(
          syncableElement.id,
          syncableElement.version,
        );
      }

      Router.emit(data.type, data.payload);
      PlanningCollab.lastBroadcastedOrReceivedSceneVersion =
        getSceneVersion(elements);
    }
  };

  static getLastBroadcastedOrReceivedSceneVersion = () => {
    return PlanningCollab.lastBroadcastedOrReceivedSceneVersion;
  };

  static setLastBroadcastedOrReceivedSceneVersion = (version: number) => {
    PlanningCollab.lastBroadcastedOrReceivedSceneVersion = version;
  };

  static reconcileElements = (
    remoteElements: readonly ExcalidrawElement[],
  ): ReconciledElements => {
    const localElements =
      PlanningCollab.excalidrawAPI.getSceneElementsIncludingDeleted();
    const appState = PlanningCollab.excalidrawAPI.getAppState();
    // remoteElements = restoreElements(remoteElements, null);

    const reconciledElements = _reconcileElements(
      localElements,
      remoteElements,
      appState,
    );

    // Avoid broadcasting to the rest of the collaborators the scene
    // we just received!
    // Note: this needs to be set before updating the scene as it
    // synchronously calls render.
    PlanningCollab.setLastBroadcastedOrReceivedSceneVersion(
      getSceneVersion(reconciledElements),
    );
    return reconciledElements;
  };

  static updateAllElements = (
    remoteElements: readonly ExcalidrawElement[],
  ): ReconciledElements => {
    const appState = PlanningCollab.excalidrawAPI.getAppState();

    const reconciledElements = _reconcileElements([], remoteElements, appState);

    // Avoid broadcasting to the rest of the collaborators the scene
    // we just received!
    // Note: this needs to be set before updating the scene as it
    // synchronously calls render.
    PlanningCollab.setLastBroadcastedOrReceivedSceneVersion(
      getSceneVersion(reconciledElements),
    );
    return reconciledElements;
  };

  static handleRemoteSceneUpdate = (
    elements: ReconciledElements,
    { init = false }: { init?: boolean } = {},
  ) => {
    PlanningCollab.excalidrawAPI.updateScene({
      elements,
      commitToHistory: !!init,
    });

    // We haven't yet implemented multiplayer undo functionality, so we clear the undo stack
    // when we receive any messages from another peer. This UX can be pretty rough -- if you
    // undo, a user makes a change, and then try to redo, your element(s) will be lost. However,
    // right now we think this is the right tradeoff.
    PlanningCollab.excalidrawAPI.history.clear();
  };
}

export type TPlanningCollabClass = PlanningCollab;
