/**
 * Import React
 */
import React, { useState } from "react";

/**
 * Import Styles
 */
import "./Resize.scss";

/**
 * Type props
 */
type ResizeProps = {
  direction: "left" | "right";
  onResize: (event: PointerEvent, direction: "e" | "w") => void;
};

export const Resize = React.memo(({ direction, onResize }: ResizeProps) => {
  const [isDragging, setIsDragging] = useState(false);

  const convertDirection = (direction: "left" | "right") => {
    return direction === "left" ? "w" : "e";
  };

  const onMove = (event: PointerEvent) => {
    onResize(event, convertDirection(direction));
  };

  const startDrag = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDragging(true);

    window.addEventListener("mouseup", stopDrag);
    window.addEventListener("pointermove", onMove);
  };

  const stopDrag = () => {
    // Удаление обработчиков событий
    window.removeEventListener("pointermove", onMove);
    window.removeEventListener("mouseup", stopDrag);
    setIsDragging(false);
  };

  return (
    <div
      className={`resize ${direction} ${isDragging ? "isDragging" : ""}`}
      onMouseDown={startDrag}
    />
  );
});
