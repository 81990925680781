import React from "react";
import "./CustomCheckbox.scss";

type CustomCheckboxProps = {
  checked: boolean;
  onChangeChecked: (value: boolean) => void;
  word?: string;
};

export const CustomCheckbox = React.memo(
  ({ checked, word, onChangeChecked }: CustomCheckboxProps) => {
    return (
      <div className="container">
        <label className="switch" htmlFor="checkbox">
          <input
            onChange={(e) => onChangeChecked(e.target.checked)}
            checked={checked}
            type="checkbox"
            id="checkbox"
          />
          <div className="slider round"></div>
        </label>
        {word && <span className="switch__word">{word}</span>}
      </div>
    );
  },
);
