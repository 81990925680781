import { memo } from "react";
import "./DotLine.scss";
import clsx from "clsx";

type DotLineProps = {
  color: string;
  reverse?: boolean;
};

export const DotLine = memo(({ color, reverse = false }: DotLineProps) => (
  <div className={clsx("DotLine", { reverse })}>
    <div className="DotLine-dot" style={{ background: color }} />
    <div className="DotLine-line" style={{ background: color }} />
  </div>
));
