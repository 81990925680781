/**
 * Utils
 */
import { getDefaultAppState } from "../../src/appState";
import { IElements, IFile, IScene } from "./types";
import { clearElementsForLocalStorage } from "../../src/element";
import { ExcalidrawElement } from "../../src/element/types";
import { DataURL, NormalizedZoomValue } from "../../src/types";
import { dataURLToFile } from "../../src/data/blob";
import axios from "axios";
import { urlToDataUrl } from "../../src/data/image";

/**
 * Consts
 */
export const BACKEND_URL =
  import.meta.env.VITE_BACKEND_URL ?? "http://172.130.3.5:9093"; // "https://board.planninggg.com/service";
export const WS_URL =
  import.meta.env.VITE_WS_URL ?? "ws://172.130.3.5:9093/ws/"; // "wss://board.planninggg.com/service/ws/" ;

/**
 * Instance
 */
export class ApiExcalidraw {
  static async getScene(scene_id: number): Promise<IScene | null> {
    try {
      const responseScene = await fetch(
        `${BACKEND_URL}/api/scene/one?id=${scene_id}`,
      );
      const jsonScene = await responseScene.json();
      if (jsonScene.success) {
        const scene: IScene = jsonScene.scene;
        return scene;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  static async updateScene(
    scene_id: number,
    options: { scrollX: number; scrollY: number; zoom: number },
  ): Promise<IScene | null> {
    try {
      const responseScene = await fetch(
        `${BACKEND_URL}/api/scene?id=${scene_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify(options),
        },
      );
      const jsonScene = await responseScene.json();
      if (jsonScene.success) {
        const scene: IScene = jsonScene.scene;
        return scene;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  static async getElements(scene_id: number): Promise<IElements | null> {
    try {
      const responseElements = await fetch(
        `${BACKEND_URL}/api/elements/bySceneId?scene_id=${scene_id}`,
      );
      const jsonElements = await responseElements.json();

      if (jsonElements.success) {
        const elements: IElements = jsonElements.elements;
        return elements;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  static async getFiles(
    files: string[],
    scene_id?: string,
  ): Promise<IFile[] | null> {
    try {
      if (!scene_id) {
        return null;
      }
      const responseElements = await fetch(
        `${BACKEND_URL}/api/files?scene_id=${scene_id}&ids=${files}`,
      );
      const jsonElements = await responseElements.json();

      if (jsonElements.success) {
        const elements: IFile[] = jsonElements.files;
        const newElements = await Promise.all(
          elements.map(async (key) => {
            try {
              const dataURL = await urlToDataUrl(
                `${BACKEND_URL}/static${key.link}`,
              );
              return { ...key, dataURL, id: key.file_id };
            } catch (dataURLError) {
              // Handle the error or decide what to do in case of failure
              console.error("Error in urlToDataUrl:", dataURLError);
              return { ...key, dataURL: "", id: key.file_id };
            }
          }),
        );

        return newElements;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  static async updateElements(
    scene_id: number,
    elements: ExcalidrawElement[],
  ): Promise<IScene | null> {
    try {
      const responseElements = await fetch(
        `${BACKEND_URL}/api/elements/bySceneId?scene_id=${scene_id}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
          method: "PATCH",
          body: JSON.stringify({ value: elements }),
        },
      );

      const jsonElements = await responseElements.json();
      if (jsonElements.success) {
        const elements: IScene = jsonElements.scene;
        return elements;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  static async createFile(
    id: string,
    scene_id: number,
    mimeType: string,
    dataURL: DataURL,
  ): Promise<IFile | null> {
    try {
      const file = dataURLToFile(dataURL, id);
      const formData = new FormData();
      formData.append("id", id);
      formData.append("scene_id", scene_id.toString());
      formData.append("mimeType", mimeType);
      formData.append("file", file);

      const responseFile = await axios.post(
        `${BACKEND_URL}/api/files`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );

      if (responseFile?.data?.success && responseFile?.data?.file) {
        const file: IFile = responseFile.data.file;
        return file;
      }
      return null;
    } catch (e: any) {
      return null;
    }
  }

  /**
   * Получить прочитанные сообщения
   */
  static async getReadMessages(scene_id?: string, user_id?: string | null) {
    /**
     * Если не переданы сцена или Id пользователя, возвращаем null
     */
    if (!scene_id || !user_id) {
      return null;
    }

    /**
     * Отправляем запрос на получение пользователей
     */
    const responseReadMessages = await fetch(
      `${BACKEND_URL}/api/readMessages/bySceneIdUserId?scene_id=${scene_id}&user_id=${user_id}`,
    );

    /**
     * Переводим в json
     */
    const jsonReadMessages = await responseReadMessages.json();

    /**
     * Если успешно, возвращаем
     */
    if (jsonReadMessages.success) {
      return jsonReadMessages.readMessages;
    }

    /**
     * Возвращаем Null
     */
    return null;
  }

  /**
   * Создать запись о прочитанном сообщении
   */
  static async createReadMessage(
    message_id: string,
    comment_id: string,
    user_id: string,
    scene_id: number,
  ) {
    /**
     * Отправляем запрос о создании
     */
    const response = await axios.post(`${BACKEND_URL}/api/readMessages`, {
      message_id,
      comment_id,
      user_id,
      scene_id: `${scene_id}`,
    });

    /**
     * Если успешно, возвращаем
     */
    if (response.data?.success) {
      return response.data.readMessage;
    }

    /**
     * Возвращаем Null
     */
    return null;
  }

  /**
   * Удалить запись о прочитанном сообщении
   */
  static async deleteReadMessage(id: number) {
    /**
     * Отправляем запрос о удалении
     */
    const response = await axios.delete(`${BACKEND_URL}/api/readMessages`, {
      params: {
        id,
      },
    });

    /**
     * Если успешно, возвращаем
     */
    if (response.data?.success) {
      return response.data.readMessage;
    }

    /**
     * Возвращаем Null
     */
    return null;
  }
}

export const importSceneFromApi = async (scene_id: number) => {
  let elements: ExcalidrawElement[] = [];
  let appState = null;
  try {
    const scene = await ApiExcalidraw.getScene(scene_id);

    if (scene) {
      appState = {
        ...getDefaultAppState(),
        zoom: { value: scene.zoom as NormalizedZoomValue },
        scrollX: scene.scrollX,
        scrollY: scene.scrollY,
        scene_id: scene.id,
      };
      const savedElements = await ApiExcalidraw.getElements(scene_id);

      if (savedElements) {
        elements = clearElementsForLocalStorage(
          JSON.parse(savedElements.value),
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  } catch (e) {
    return null;
  }

  return { elements, appState };
};
