import { CURSOR_TYPE, MIME_TYPES, THEME } from "./constants";
import OpenColor from "open-color";
import { AppState, DataURL } from "./types";
import { isHandToolActive, isEraserActive } from "./appState";

const laserPointerCursorSVG_tag = `<svg viewBox="0 0 24 24"  stroke-width="1" width="28" height="28" xmlns="http://www.w3.org/2000/svg">`;
const laserPointerCursorBackgroundSVG = `<path d="M6.164 11.755a5.314 5.314 0 0 1-4.932-5.298 5.314 5.314 0 0 1 5.311-5.311 5.314 5.314 0 0 1 5.307 5.113l8.773 8.773a3.322 3.322 0 0 1 0 4.696l-.895.895a3.322 3.322 0 0 1-4.696 0l-8.868-8.868Z" style="fill:#fff"/>`;
const laserPointerCursorIconSVG = `<path stroke="#1b1b1f" fill="#fff" d="m7.868 11.113 7.773 7.774a2.359 2.359 0 0 0 1.667.691 2.368 2.368 0 0 0 2.357-2.358c0-.625-.248-1.225-.69-1.667L11.201 7.78 9.558 9.469l-1.69 1.643v.001Zm10.273 3.606-3.333 3.333m-3.25-6.583 2 2m-7-7 3 3M3.664 3.625l1 1M2.529 6.922l1.407-.144m5.735-2.932-1.118.866M4.285 9.823l.758-1.194m1.863-6.207-.13 1.408"/>`;

const laserPointerCursorDataURL_lightMode = `data:${
  MIME_TYPES.svg
},${encodeURIComponent(
  `${laserPointerCursorSVG_tag}${laserPointerCursorIconSVG}</svg>`,
)}`;
const laserPointerCursorDataURL_darkMode = `data:${
  MIME_TYPES.svg
},${encodeURIComponent(
  `${laserPointerCursorSVG_tag}${laserPointerCursorBackgroundSVG}${laserPointerCursorIconSVG}</svg>`,
)}`;

const stickyNoteSvgPath = `
<path
xmlns="http://www.w3.org/2000/svg"
d="M4 4v16h9v-5a2 2 0 0 1 2-2h5V4H4zm0-2h16a2 2 0 0 1 2 2v10.172a2 2 0 0 1-.586 1.414l-5.828 5.828a2 2 0 0 1-1.414.586H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"
fillRule="nonzero"
fill="currentColor"
></path>`;

const stickyNoteSvgPathDark = `
<path
xmlns="http://www.w3.org/2000/svg"
d="M4 4v16h9v-5a2 2 0 0 1 2-2h5V4H4zm0-2h16a2 2 0 0 1 2 2v10.172a2 2 0 0 1-.586 1.414l-5.828 5.828a2 2 0 0 1-1.414.586H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2z"
fillRule="nonzero"
fill="#fff"
></path>`;

const stickyNoteSvgLight = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `${laserPointerCursorSVG_tag}${stickyNoteSvgPath}</svg>`,
)}`;

const stickyNoteSvgDark = `data:${MIME_TYPES.svg},${encodeURIComponent(
  `${laserPointerCursorSVG_tag}${stickyNoteSvgPathDark}</svg>`,
)}`;

const svgCustomComment = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_13535_296129)">
<path d="M25 8C25 5.79086 23.2091 4 21 4H11C8.79086 4 7 5.79086 7 8V15.4286C7 17.6377 8.79086 19.4286 11 19.4286H12.7857L16 23L19.2143 19.4286H21C23.2091 19.4286 25 17.6377 25 15.4286V8Z" fill="#181818"/>
<path d="M13.0246 19.2135L12.9289 19.1071H12.7857H11C8.96838 19.1071 7.32143 17.4602 7.32143 15.4286V8C7.32143 5.96838 8.96838 4.32143 11 4.32143H21C23.0316 4.32143 24.6786 5.96838 24.6786 8V15.4286C24.6786 17.4602 23.0316 19.1071 21 19.1071H19.2143H19.0711L18.9754 19.2135L16 22.5195L13.0246 19.2135Z" stroke="white" stroke-opacity="0.9" stroke-width="0.642857"/>
</g>
<defs>
<filter id="filter0_d_13535_296129" x="0.571429" y="0.142858" width="30.8571" height="31.8571" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="2.57143"/>
<feGaussianBlur stdDeviation="3.21429"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0 0.0627451 0 0 0 0.8 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13535_296129"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13535_296129" result="shape"/>
</filter>
</defs>
</svg>
`;

const customCommenUrl = `data:${MIME_TYPES.svg},${encodeURIComponent(
  svgCustomComment,
)}`;

export const resetCursor = (
  interactiveCanvas: HTMLCanvasElement | HTMLElement | null,
) => {
  if (interactiveCanvas) {
    interactiveCanvas.style.cursor = "";
  }
};

export const setCursor = (
  interactiveCanvas: HTMLCanvasElement | HTMLElement | null,
  cursor: string,
) => {
  if (interactiveCanvas) {
    interactiveCanvas.style.cursor = cursor;
  }
};

let eraserCanvasCache: any;
let previewDataURL: string;
export const setEraserCursor = (
  interactiveCanvas: HTMLCanvasElement | HTMLElement | null,
  theme: AppState["theme"],
) => {
  const cursorImageSizePx = 20;

  const drawCanvas = () => {
    const isDarkTheme = theme === THEME.DARK;
    eraserCanvasCache = document.createElement("canvas");
    eraserCanvasCache.theme = theme;
    eraserCanvasCache.height = cursorImageSizePx;
    eraserCanvasCache.width = cursorImageSizePx;
    const context = eraserCanvasCache.getContext("2d")!;
    context.lineWidth = 1;
    context.beginPath();
    context.arc(
      eraserCanvasCache.width / 2,
      eraserCanvasCache.height / 2,
      5,
      0,
      2 * Math.PI,
    );
    context.fillStyle = isDarkTheme ? OpenColor.black : OpenColor.white;
    context.fill();
    context.strokeStyle = isDarkTheme ? OpenColor.white : OpenColor.black;
    context.stroke();
    previewDataURL = eraserCanvasCache.toDataURL(MIME_TYPES.svg) as DataURL;
  };
  if (!eraserCanvasCache || eraserCanvasCache.theme !== theme) {
    drawCanvas();
  }

  setCursor(
    interactiveCanvas,
    `url(${previewDataURL}) ${cursorImageSizePx / 2} ${
      cursorImageSizePx / 2
    }, auto`,
  );
};

export const setCursorForShape = (
  interactiveCanvas: HTMLCanvasElement | HTMLElement | null,
  appState: Pick<AppState, "activeTool" | "theme">,
) => {
  if (!interactiveCanvas) {
    return;
  }
  if (appState.activeTool.type === "selection") {
    resetCursor(interactiveCanvas);
  } else if (appState.activeTool.type === "stickyNote") {
    const url =
      appState.theme === THEME.LIGHT ? stickyNoteSvgLight : stickyNoteSvgDark;
    interactiveCanvas.style.cursor = `url(${url}), auto`;
  } else if (appState.activeTool.type === "customComment") {
    const url = customCommenUrl;
    interactiveCanvas.style.cursor = `url("${url}") 16 32, auto`;
  } else if (isHandToolActive(appState)) {
    interactiveCanvas.style.cursor = CURSOR_TYPE.GRAB;
  } else if (isEraserActive(appState)) {
    setEraserCursor(interactiveCanvas, appState.theme);
    // do nothing if image tool is selected which suggests there's
    // a image-preview set as the cursor
    // Ignore custom type as well and let host decide
  } else if (appState.activeTool.type === "laser") {
    const url =
      appState.theme === THEME.LIGHT
        ? laserPointerCursorDataURL_lightMode
        : laserPointerCursorDataURL_darkMode;
    interactiveCanvas.style.cursor = `url(${url}), auto`;
  } else if (!["image", "custom"].includes(appState.activeTool.type)) {
    interactiveCanvas.style.cursor = CURSOR_TYPE.CROSSHAIR;
  } else if (appState.activeTool.type !== "image") {
    interactiveCanvas.style.cursor = CURSOR_TYPE.AUTO;
  }
};
