import clsx from "clsx";
import { DEFAULT_ELEMENT_BACKGROUND_PICKS } from "../../../../colors";
import "./Picks.scss";

interface PicksProps {
  onChange: (color: string) => void;
  activeColor: string;
  picks?: readonly string[];
}

export const Picks = ({ onChange, activeColor, picks }: PicksProps) => {
  let colors;

  colors = DEFAULT_ELEMENT_BACKGROUND_PICKS;

  // this one can overwrite defaults
  if (picks) {
    colors = picks;
  }

  if (!colors) {
    console.error("Invalid type for TopPicks");
    return null;
  }

  return (
    <div className="picks">
      {colors.map((color: string) => (
        <div className="picks__button-wrapper" key={color}>
          <button
            className={clsx("picks__button", {
              active: color === activeColor,
              "is-transparent": color === "transparent" || !color,
            })}
            style={{ "--swatch-color": color }}
            key={color}
            type="button"
            title={color}
            onClick={() => onChange(color)}
          >
            <div className="picks__button-outline" />
          </button>
        </div>
      ))}
    </div>
  );
};
