import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { CreateCommentArrow } from "../../../icons";
import "./FooterComment.scss";
import { KEYS } from "../../../../keys";
import clsx from "clsx";

type FooterCommentProps = {
  imagePath: string;
  value: string;
  setValue: (value: string) => void;
  onSubmit: () => void;
};

export const FooterComment = React.memo(
  ({ imagePath, value, onSubmit, setValue }: FooterCommentProps) => {
    const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === KEYS.ENTER && !event.shiftKey) {
        event.preventDefault();
        event.stopPropagation();
        onSubmit();
      }
    };
    return (
      <div className="FooterComment">
        <div className="FooterComment__image">
          <img src={imagePath} />
        </div>
        <TextareaAutosize
          autoFocus
          placeholder="Сообщение"
          value={value}
          onKeyDown={onKeyDown}
          className="FooterComment__textarea excalidraw-wysiwyg"
          maxLength={2000}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        />
        <div
          className={clsx("FooterComment__icon", { active: value })}
          onClick={onSubmit}
        >
          {CreateCommentArrow}
        </div>
      </div>
    );
  },
);
