import React from "react";
import { CustomCheckbox } from "./CustomCheckbox";
import "./HeaderComment.scss";
import { Picks } from "./Picks";
import { ButtonDropDown } from "./ButtonDropDown";

type TDropDownData = {
  icon: JSX.Element;
  text: string;
  onClick: () => void;
};

type TColorPickerData = {
  onChangeColor: (value: string) => void;
  colors: string[];
};

type HeaderCommentProps = {
  resolved: boolean;
  dropdownData: TDropDownData[];
  colorPickerData: TColorPickerData;
  activeColor: string;
  onChangeResolved: (value: boolean) => void;
};

export const HeaderComment = React.memo(
  ({
    onChangeResolved,
    colorPickerData,
    resolved,
    activeColor,
    dropdownData,
  }: HeaderCommentProps) => {
    return (
      <div className="HeaderComment">
        <CustomCheckbox
          word="Решено"
          checked={resolved}
          onChangeChecked={onChangeResolved}
        />
        <Picks
          activeColor={activeColor}
          picks={colorPickerData.colors}
          onChange={colorPickerData.onChangeColor}
        />
        <ButtonDropDown dropdownData={dropdownData} />
      </div>
    );
  },
);
