import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import TextareaAutosize from "react-textarea-autosize";
import "./Message.scss";
import { IUser } from "../../../../excalidraw-app/data/PlanningData";
import { BACKEND_URL } from "../../../../excalidraw-app/api";
import { getHashParam } from "../../../utils/index";

// Тип для сообщения
type TMessage = {
  id: string;
  value: string;
  date: string;
  autor: string;
  user_id: string;
};

// Пропсы для компонента Message
type MessageProps = {
  message: TMessage;
  editable: boolean;
  user?: IUser | null;
  onDeleteMessage: (messageId: string) => void;
  onEditMessage: (messageId: string, newValue: string) => void;
};

// Компонент Message
export const Message = React.memo(
  ({
    message,
    editable,
    onDeleteMessage,
    onEditMessage,
    user,
  }: MessageProps) => {
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [value, setValue] = useState<string>(message.value);
    const textareaRef = useRef<HTMLTextAreaElement | null>(null);
    const messageValueRef = useRef<HTMLDivElement | null>(null);

    // Обработчик отмены редактирования
    const onCancel = useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        setIsEditable(false);
        setValue(message.value);
      },
      [message.value],
    );

    // Функция преобразования даты
    function formatDate(date: Date): string {
      const months: string[] = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];

      const day: number = date.getDate();
      const monthIndex: number = date.getMonth();
      const year: number = date.getFullYear();
      const currentYear: number = new Date().getFullYear(); // Получаем текущий год
      const hours: number = date.getHours();
      let minutes: number | string = date.getMinutes();

      // Добавляем ведущий ноль к минутам, если это необходимо
      minutes = minutes < 10 ? `0${minutes}` : minutes.toString();

      // Проверяем, соответствует ли год текущему, и форматируем вывод соответственно
      let formattedDate: string = `${day} ${months[monthIndex]}`;
      if (year !== currentYear) {
        formattedDate += ` ${year}`;
      }
      formattedDate += ` ${hours}:${minutes}`;

      return formattedDate;
    }

    // Обработчик сохранения редактирования сообщения
    const onSaveEdit = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onEditMessage(message.id, value);
      setIsEditable(false); // Выходим из режима редактирования
    };

    // Обработчик удаления сообщения
    const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      onDeleteMessage(message.id);
    };

    // Обработчик клика вне компонента для выхода из режима редактирования
    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        if (
          messageValueRef.current &&
          !messageValueRef.current.contains(event.target as Node) &&
          isEditable
        ) {
          setIsEditable(false);
          setValue(message.value);
        }
      },
      [isEditable, message.value],
    );

    // Подписка на событие клика мыши
    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [handleClickOutside]);

    // Автофокус на textarea при входе в режим редактирования
    useEffect(() => {
      if (isEditable && textareaRef.current) {
        textareaRef.current.focus();
        textareaRef.current.setSelectionRange(
          textareaRef.current.value.length,
          textareaRef.current.value.length,
        );
      }
    }, [isEditable]);

    return (
      <div className="message">
        <div className="message__header">
          <div className="message__header--image">
            <img
              src={
                user?.image && getHashParam("planning_url")
                  ? `${getHashParam("planning_url")}/service/static/${
                      user.image
                    }`
                  : `${BACKEND_URL}/static/default/replaceUser.jpg`
              }
              alt=""
            />
          </div>
          <div className="message__header--autor">
            {user?.first_name || message?.autor || "Неизвестно"}
          </div>
        </div>
        <div
          className={clsx("message__value", {
            active: editable,
            editable: isEditable,
          })}
          ref={messageValueRef}
          onClick={() => {
            if (editable) {
              setIsEditable(true);
            }
          }}
        >
          <TextareaAutosize
            disabled={!isEditable}
            ref={textareaRef}
            value={value}
            className="message__value--textarea excalidraw-wysiwyg"
            onChange={(e) => setValue(e.target.value)}
            maxLength={2000}
          />
          {isEditable && (
            <div className="message__editable-menu">
              <div className="message__editable-menu--left">
                <div className="save editable__menu-item" onClick={onSaveEdit}>
                  Сохранить
                </div>
                <div className="cancel editable__menu-item" onClick={onCancel}>
                  Отменить
                </div>
              </div>
              <div className="message__editable-menu--right">
                <div className="delete editable__menu-item" onClick={onDelete}>
                  Удалить
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={clsx("message__date", { disabled: isEditable })}>
          {formatDate(new Date(message.date))}
        </div>
      </div>
    );
  },
);
