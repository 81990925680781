/**
 * Import Data
 */
import { PlanningData } from "./PlanningData";

/**
 * Import Api
 */
import { ApiExcalidraw } from "../api";

/**
 * Import Types
 */
import type { ExcalidrawCustomCommentElement } from "../../src/element/types";

/**
 * Interface of read messages
 */
interface IReadMessagesData {
  [message_id: string]: IReadMessage | boolean;
}

interface IReadMessage {
  id: number;
  comment_id: string;
  user_id: string;
  message_id: string;
  scene_id: string;
}

/**
 * Instance
 */
class ReadCommentsData {
  /**
   * Данные по прочитанным сообщениям в комментарии
   */
  data: IReadMessagesData = {};

  /**
   * Получить прочитанные сообщения
   */
  async getReadMessages(scene_id: string, user_id: string | null) {
    const readMessages = await ApiExcalidraw.getReadMessages(scene_id, user_id);
    if (readMessages) {
      for (const reagMessage of readMessages) {
        this.data[reagMessage.message_id] = reagMessage;
      }
    }
    return readMessages;
  }

  /**
   * Создать запись о прочитанном сообщении
   */
  async createReadMessage(
    message_id: string,
    comment_id: string,
    user_id?: string,
    scene_id?: number | null,
  ) {
    if (
      user_id &&
      scene_id &&
      !this.data[message_id] &&
      message_id !== user_id
    ) {
      this.data[message_id] = true;

      ApiExcalidraw.createReadMessage(
        message_id,
        comment_id,
        user_id,
        scene_id,
      ).then((readMessage: IReadMessage | null) => {
        if (readMessage) {
          this.data[readMessage.message_id] = readMessage;
        }
      });
    }
  }

  /**
   * Удалить запись о прочитанном сообщении
   */
  async deleteReadMessage(message_id: string) {
    const readMessage = this.data[message_id];
    if (readMessage) {
      if (typeof readMessage !== "boolean") {
        ApiExcalidraw.deleteReadMessage(readMessage.id);
      }
      delete this.data[message_id];
    }
  }

  /**
   * Функция проверки прочитан ли комментарий
   */
  isReadComment(comment: ExcalidrawCustomCommentElement) {
    let result = true;
    for (const message of comment.messages) {
      if (!this.data[message.id]) {
        result = false;
        break;
      }
    }

    return result;
  }
}

/**
 * Instance
 * @type {ReadCommentsData}
 */
const instance = new ReadCommentsData();

/**
 * Export
 */
export { instance as ReadCommentsData };
