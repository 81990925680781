/**
 * Import React
 */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

/**
 * Import styles
 */
import "./CreateComment.scss";

/**
 * Import Components
 */
import { EyeOpen } from "../icons";
import { Popover } from "../Popover";
import { DotLine } from "./components/DotLine";
import { FooterComment } from "./components/Footer/FooterComment";
import { HeaderComment } from "./components/Header/HeaderComment";

/**
 * Import Utils
 */
import clsx from "clsx";
import { useExcalidrawAppState } from "../App";
import { getHashParam } from "../../utils/index";

/**
 * Import Data
 */
import { PlanningData } from "../../../excalidraw-app/data/PlanningData";

/**
 * Import Consts
 */
import { BACKEND_URL } from "../../../excalidraw-app/api";

/**
 * Type Props
 */
type CreateCommentProps = {
  onCreate: (
    value: string,
    color: string,
    isRead: boolean,
    resolved: boolean,
  ) => void;
  top: number;
  left: number;
  onDelete: () => void;
};

export const CreateComment = React.memo(
  ({ onCreate, left, top, onDelete }: CreateCommentProps) => {
    const authUser = PlanningData.authUser;
    const appState = useExcalidrawAppState();
    const [commentValue, setCommentValue] = React.useState("");
    const [selectColor, setSelectColor] = React.useState("#F97D56");
    const [resolved, setResolved] = React.useState(false);
    const [isRead, setIsRead] = React.useState(true);
    const innerRef = useRef<HTMLDivElement>(null);
    const [lastTop, setLastTop] = useState(0);
    const [position, setPosition] = useState<"left" | "right">("right");
    const colorPickerData = {
      onChangeColor: setSelectColor,
      colors: ["#368F5D", "#DCB358", "#F97D56", "#9951E4"],
    };

    const dropdownData = [
      {
        icon: EyeOpen,
        text: "Отметить как непрочитанный",
        onClick: () => setIsRead(false),
      },
      {
        icon: EyeOpen,
        text: "Удалить",
        onClick: onDelete,
      },
    ];

    useLayoutEffect(() => {
      const inner = innerRef.current;
      if (inner) {
        const { height, top } = inner.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        let newLastTop = lastTop; // Временная переменная для хранения нового значения

        // Проверяем, не уходит ли элемент за верхнюю границу экрана.
        if (top < 0) {
          newLastTop += Math.abs(top);
        } else if (top > 0 && lastTop > 0) {
          // Если элемент полностью в пределах видимости и lastTop > 0,
          // уменьшаем lastTop, не допуская выхода элемента за верхнюю границу.
          newLastTop = Math.max(0, lastTop - Math.min(lastTop, top));
        }

        // Проверяем, не уходит ли элемент за нижнюю границу экрана.
        const bottomEdge = top + height; // Нижняя граница элемента
        if (bottomEdge > viewportHeight) {
          newLastTop -= bottomEdge - viewportHeight;
        } else if (bottomEdge < viewportHeight && lastTop < 0) {
          // Если элемент полностью в пределах видимости и lastTop < 0,
          // увеличиваем lastTop, не допуская выхода элемента за нижнюю границу.
          const spaceBelow = viewportHeight - bottomEdge;
          newLastTop = Math.min(
            0,
            lastTop + Math.min(Math.abs(lastTop), spaceBelow),
          );
        }

        // Обновляем lastTop только если это необходимо
        if (newLastTop !== lastTop) {
          setLastTop(newLastTop);
        }

        if (left + 60 + 340 > viewportWidth && position !== "left") {
          setPosition("left");
        } else if (left + 60 + 340 < viewportWidth && position !== "right") {
          setPosition("right");
        }
      }
    }, [left, top]); // Добавляем x и y в массив зависимостей

    useEffect(() => {
      const inner = innerRef.current;
      if (inner) {
        inner.style.top = `calc(50% + ${lastTop}px)`;
      }
    }, [lastTop]);

    return (
      <Popover
        onCloseRequest={() => {
          onCreate(commentValue, selectColor, isRead, resolved);
        }}
        top={top - 9}
        left={left - (position === "left" ? -3 : 3)}
        fitInViewport={true}
        checkViewPort={false}
        offsetLeft={appState.offsetLeft}
        offsetTop={appState.offsetTop}
        viewportWidth={appState.width}
        viewportHeight={appState.height}
      >
        <div
          className={clsx("createComment__wrapper", {
            left: position === "left",
          })}
        >
          <DotLine color={selectColor} reverse={position === "left"} />
          <div className="createComment__inner" ref={innerRef}>
            <HeaderComment
              resolved={resolved}
              activeColor={selectColor}
              onChangeResolved={setResolved}
              colorPickerData={colorPickerData}
              dropdownData={dropdownData}
            />
            <FooterComment
              imagePath={
                authUser?.image && getHashParam("planning_url")
                  ? `${getHashParam("planning_url")}/service/static/${
                      authUser.image
                    }`
                  : `${BACKEND_URL}/static/default/replaceUser.jpg`
              }
              value={commentValue}
              setValue={setCommentValue}
              onSubmit={() =>
                onCreate(commentValue, selectColor, isRead, resolved)
              }
            />
          </div>
        </div>
      </Popover>
    );
  },
);
