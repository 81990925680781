import React, { useCallback, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import "./ButtonDropDown.scss";
type TDropDownData = {
  icon: JSX.Element;
  text: string;
  onClick: () => void;
};

type ButtonDropDownProps = {
  dropdownData: TDropDownData[];
};

export const ButtonDropDown = React.memo(
  ({ dropdownData }: ButtonDropDownProps) => {
    const [openDropdown, setOpenDropdown] = useState(false);
    const buttonRef = useRef<HTMLDivElement | null>(null);

    const handleClickOutside = useCallback((event: MouseEvent) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        setOpenDropdown(false);
      }
    }, []);

    useEffect(() => {
      // Функция для добавления обработчика события
      const addEventListener = () =>
        document.addEventListener("mousedown", handleClickOutside);
      // Функция для удаления обработчика события
      const removeEventListener = () =>
        document.removeEventListener("mousedown", handleClickOutside);

      if (openDropdown) {
        // Если выпадающее меню активно, добавляем обработчик события
        addEventListener();
      } else {
        // Если выпадающее меню не активно, удаляем обработчик события
        removeEventListener();
      }

      // Функция очистки для useEffect, которая будет вызвана при демонтировании компонента
      // или при изменении зависимостей useEffect
      return () => {
        removeEventListener();
      };
    }, [openDropdown, handleClickOutside]); // Добавляем showDropDown в список зависимостей

    return (
      <div
        ref={buttonRef}
        className={clsx("ButtonDropDown", { active: openDropdown })}
        onClick={() => setOpenDropdown(!openDropdown)}
      >
        <div className="ButtonDropDown__dot"></div>
        <div className="ButtonDropDown__dot"></div>
        <div className="ButtonDropDown__dot"></div>

        {openDropdown && (
          <div className="ButtonDropDown__modal">
            {dropdownData.map((item) => {
              return (
                <div
                  className="ButtonDropDown__modal-item"
                  onClick={item.onClick}
                >
                  <div className="ButtonDropDown__modal-icon">{item.icon}</div>
                  <div className="ButtonDropDown__modal-text">{item.text}</div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  },
);
