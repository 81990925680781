import "./ToolIcon.scss";
import "./CustomCommentArrow.scss";
import clsx from "clsx";
import { ToolButton } from "./ToolButton";
import { ArrowComment, EyeClose, EyeOpen } from "./icons";
import { useCallback, useEffect, useRef, useState } from "react";

type LockIconProps = {
  title?: string;
  name?: string;
  onChange(): void;
  showResolvedCustomComment: boolean;
  isMobile?: boolean;
};

export const CustomCommentArrow = (props: LockIconProps) => {
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const buttonRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setShowDropDown(false);
    }
  }, []);

  const onClick = () => {
    props.onChange();
    setShowDropDown(false);
  };

  useEffect(() => {
    // Функция для добавления обработчика события
    const addEventListener = () =>
      document.addEventListener("mousedown", handleClickOutside);
    // Функция для удаления обработчика события
    const removeEventListener = () =>
      document.removeEventListener("mousedown", handleClickOutside);

    if (showDropDown) {
      // Если выпадающее меню активно, добавляем обработчик события
      addEventListener();
    } else {
      // Если выпадающее меню не активно, удаляем обработчик события
      removeEventListener();
    }

    // Функция очистки для useEffect, которая будет вызвана при демонтировании компонента
    // или при изменении зависимостей useEffect
    return () => {
      removeEventListener();
    };
  }, [showDropDown, handleClickOutside]); // Добавляем showDropDown в список зависимостей

  return (
    <div className="CustomCommentArrow__wrapper" ref={buttonRef}>
      <ToolButton
        className={clsx("Shape", "CustomCommentArrow", {
          active: showDropDown,
        })}
        type="button"
        icon={ArrowComment}
        name="editor-current-shape"
        aria-label={`${props.title}`}
        onClick={() => setShowDropDown(!showDropDown)}
      />
      {showDropDown && (
        <div className="CustomCommentArrow__dropdown" onClick={onClick}>
          <div className="CustomCommentArrow__dropdown-inner">
            <div className="icon">
              {props.showResolvedCustomComment ? EyeClose : EyeOpen}
            </div>
            <span>
              {props.showResolvedCustomComment
                ? "Скрыть завершённые комментарии"
                : "Показать завершённые комментарии"}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
