import { useSetAtom } from "jotai";
import React, { useState } from "react";
import { appLangCodeAtom } from "..";
import { useI18n } from "../../src/i18n";
import { languages } from "../../src/i18n";

export const LanguageList = ({ style }: { style?: React.CSSProperties }) => {
  const { t, langCode } = useI18n();
  const setLangCode = useSetAtom(appLangCodeAtom);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`dropdown-select--wrapper ${isOpen ? "open" : ""}`}>
      <select
        className={`dropdown-select dropdown-select__language`}
        onChange={({ target }) => setLangCode(target.value)}
        onBlur={() => setIsOpen(false)}
        onMouseDown={() => setIsOpen(!isOpen)}
        value={langCode}
        aria-label={t("buttons.selectLanguage")}
        style={style}
      >
        {languages.map((lang) => (
          <option key={lang.code} value={lang.code}>
            {lang.label}
          </option>
        ))}
      </select>
    </div>
  );
};
