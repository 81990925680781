/**
 * Dependences
 */
import { ExcalidrawElement, FileId } from "../../src/element/types";
import { AppState, BinaryFileData, BinaryFiles } from "../../src/types";
import { debounce } from "../../src/utils";
import { FileManager } from "./FileManager";
import { Locker } from "./Locker";
import { ApiExcalidraw } from "../api";
import { SAVE_TO_BACKEND_TIMEOUT } from "../app_constants";
import { IFile } from "../api/types";

type SavingLockTypes = "collaboration";

interface IAppStateBackend {
  scene_id: number;
  scrollX: number;
  scrollY: number;
  zoom: number;
}

const saveDataStateToBackend = async (
  appStateBackend: IAppStateBackend,
  elements: ExcalidrawElement[],
) => {
  const { scene_id, scrollX, scrollY, zoom } = appStateBackend;
  await Promise.all([
    ApiExcalidraw.updateScene(scene_id, {
      scrollX,
      scrollY,
      zoom,
    }),
    ApiExcalidraw.updateElements(scene_id, elements),
  ]);
};

export class ServerData {
  private static _save = debounce(
    async (
      elements: readonly ExcalidrawElement[],
      appState: AppState,
      files: BinaryFiles,
      onFilesSaved: () => void,
      scene_id: number,
    ) => {
      await saveDataStateToBackend(
        {
          scene_id,
          scrollX: appState.scrollX,
          scrollY: appState.scrollY,
          zoom: appState.zoom.value,
        },
        elements as ExcalidrawElement[],
      );

      await this.fileStorage.saveFiles({
        elements,
        files,
        scene_id,
      });
      onFilesSaved();
    },
    SAVE_TO_BACKEND_TIMEOUT,
  );

  /** Saves DataState, including files. Bails if saving is paused */
  static save = (
    elements: readonly ExcalidrawElement[],
    appState: AppState,
    files: BinaryFiles,
    onFilesSaved: () => void,
    scene_id: number,
  ) => {
    // we need to make the `isSavePaused` check synchronously (undebounced)
    if (!this.isSavePaused()) {
      this._save(elements, appState, files, onFilesSaved, scene_id);
    }
  };

  static flushSave = () => {
    this._save.flush();
  };

  private static locker = new Locker<SavingLockTypes>();

  static pauseSave = (lockType: SavingLockTypes) => {
    this.locker.lock(lockType);
  };

  static resumeSave = (lockType: SavingLockTypes) => {
    this.locker.unlock(lockType);
  };

  static isSavePaused = () => {
    return document.hidden || this.locker.isLocked();
  };

  // ---------------------------------------------------------------------------

  static fileStorage = new FileManager({
    getFiles(ids, scene_id) {
      return ApiExcalidraw.getFiles(ids, scene_id).then(
        async (filesData: IFile[] | null) => {
          const loadedFiles: BinaryFileData[] = [];
          const erroredFiles = new Map<FileId, true>();

          const filesToSave: [FileId, IFile][] = [];
          if (filesData) {
            filesData.forEach((data, index) => {
              const id = ids[index];
              if (data) {
                const _data: any = {
                  ...data,
                  lastRetrieved: Date.now(),
                };
                filesToSave.push([id, _data]);
                loadedFiles.push(_data);
              } else {
                erroredFiles.set(id, true);
              }
            });
          }

          return { loadedFiles, erroredFiles };
        },
      );
    },
    async saveFiles({ addedFiles, scene_id }) {
      const savedFiles = new Map<FileId, true>();
      const erroredFiles = new Map<FileId, true>();
      await Promise.all(
        [...addedFiles].map(async ([id, fileData]) => {
          try {
            await ApiExcalidraw.createFile(
              id,
              scene_id!,
              fileData.mimeType,
              fileData.dataURL,
            );
            // await set(id, fileData, filesStore);
            savedFiles.set(id, true);
          } catch (error: any) {
            console.error(error);
            erroredFiles.set(id, true);
          }
        }),
      );

      return { savedFiles, erroredFiles };
    },
  });
}
