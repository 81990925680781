import "./ToolIcon.scss";
import "./CustomComment.scss";

import clsx from "clsx";
import { ToolButton } from "./ToolButton";
import { CustomCommentIcon } from "./icons";
import { KEYS } from "../keys";

type LockIconProps = {
  title?: string;
  name?: string;
  checked: boolean;
  onChange?(): void;
  isMobile?: boolean;
};

export const CustomCommentButton = (props: LockIconProps) => {
  return (
    <ToolButton
      className={clsx("Shape", "CustomComment")}
      type="radio"
      icon={CustomCommentIcon}
      name="editor-current-shape"
      checked={props.checked}
      title={`${props.title}`}
      aria-label={`${props.title}`}
      aria-keyshortcuts={KEYS.C}
      data-testid={`toolbar-stickyNote`}
      onChange={() => props.onChange?.()}
    />
  );
};
